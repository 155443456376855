import React from "react";
import styleVars from "../../styles/style-vars";

const CookiePolicyComponent = ({ isGlobal }) => {
  return (
    <div className={`${styleVars.page.xl} mb10`}>
      <h1 className="f2 ">
        {isGlobal ? "Spotlight's cookie policy" : "Spotlight's cookie policy"}
      </h1>
      <p className="f5" style={{ fontStyle: "italic" }}>
        Last modified: January 16, 2019
      </p>
      <div>
        <h3 className="f3  pt5 pb5 ma0">1. Introduction</h3>
        <p className="f5">
          As is common practice with almost all professional websites, when you
          use our website (“Site”), we may store cookies on your computer in
          order to facilitate and customize your use of our Site.
        </p>
        <p className="f5">
          This page describes what information they gather, how we use it, and
          why we sometimes need to store these cookies. We will also share how
          you can prevent these cookies from being stored, although that might
          downgrade or 'break' certain elements of the Site’s functionality.
        </p>
        <p className="f5">
          By using this Site you acknowledge your understanding of, and consent
          to, our use of cookies.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">2. What are Cookies?</h3>
        <p className="f5">
          Cookies are tiny (data text) files that a website stores on your
          computer’s hard drive (if your web browser permits), that can later be
          retrieved to identify you to us, and improve your experience.
        </p>
        <p className="f5 mb0">
          For more general information on cookies, you can visit:
        </p>
        <p className="f5 mb0">
          <ol style={{ listStyleType: "lower-alpha", padding: "20px" }}>
            <li>
              Wikipedia article on HTTP Cookies:{" "}
              <a
                href="https://en.wikipedia.org/wiki/HTTP_cookie"
                target="_blank"
              >
                https://en.wikipedia.org/wiki/HTTP_cookie
              </a>
              .
            </li>
            <li>
              All About Cookies.org:{" "}
              <a href="https://www.allaboutcookies.org/" target="_blank">
                https://www.allaboutcookies.org/
              </a>
              .
            </li>
          </ol>
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">3. How do we use cookies?</h3>
        <p className="f5">
          Cookies may track things such as how often you visit the site, and how
          long you spend on the site and the pages that you visit, so we can
          continue to produce engaging content. From time to time we test new
          features and make subtle changes to the way that the site is
          delivered. When we are still testing new features, these cookies may
          be used to ensure that you receive a consistent experience whilst on
          the site whilst ensuring we understand which optimisations our users
          appreciate the most.
        </p>
        <p className="f5">
          As we sell products, it's important for us to understand statistics
          about how many of the visitors to our site actually make a purchase
          and, as such, this is the kind of data that these cookies will track.
          This is important to you as it means that we can accurately make
          business predictions that allow us to monitor our advertising and
          product costs to ensure the best possible price.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">4. Disabling Cookies</h3>
        <p className="f5">
          You may delete cookies already on your computer by consulting the
          instructions for your file management software. You can also disable
          future cookies by adjusting the settings on your browser (see your
          browser Help for how to do this).{" "}
        </p>
        <p className="f5">
          Unfortunately, in most cases there are no industry standard options
          for disabling cookies without completely disabling the functionality
          and features they add to this Site. Disabling cookies may prevent or
          impair your ability to access and fully make use of certain
          functionalities and features of this Site, and possibly many other
          websites that you visit.
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">5. Third Party Cookies</h3>
        <p className="f5">
          This site uses Google Analytics, which is one of the most widespread
          and trusted analytics solutions on the web for helping us to
          understand how you use the site and ways that we can improve your
          experience. Google Analytics collects the IP address assigned to you
          on the date you visit sites, rather than your name or other
          identifying information.
        </p>
        <p className="f5">
          We also use social media buttons and/or plugins on this site that
          allow you to connect with your social network in various ways. For
          these to work, social media sites like LinkedIn will set cookies
          through our site which may be used to enhance your profile on their
          site or contribute to the data they hold for various purposes outlined
          in their respective privacy policies.
        </p>
        <p className="f5">
          Websites such as Google and other third-party vendors to our Site may
          use DART cookies to provide add-ons to our Site based upon visitors
          visiting our Site, and other websites. Visitors may decline the use of
          DART cookies by visiting the Google ad and content network Privacy
          Policy at the following URL:{" "}
          <a href="http://www.google.com/privacy_ads.html" target="_blank">
            http://www.google.com/privacy_ads.html
          </a>
        </p>
      </div>
      <div>
        <h3 className="f3  pt5 pb5 ma0">6. Reach out to us</h3>
        <p className="f5">
          Hopefully this has clarified things for you. As was previously
          mentioned, if there is something that you aren't sure you need or not,
          it's usually safer to leave cookies enabled in case it does interact
          with one of the features you use on our site. However, if you are
          still looking for more information, you can contact us via email at:{" "}
          <a href="mailto:takecontrol@spotlightandcompany.com">
            takecontrol@spotlightandcompany.com
          </a>
          .
        </p>
      </div>
    </div>
  );
};

export default CookiePolicyComponent;
