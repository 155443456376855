import React, { Component } from "react";

import Layout from "../../../components/layout/index";
import SEO from "../../../components/seo";
import CookiePolicyComponent from "../../../components/legal/cookie-policy";
import mixpanel from "../../../lib/mixpanel/index";

import "../../../layouts/index.css";

class CookiePolicyUS extends Component {
  componentDidMount() {
    mixpanel.track("COOKIE_POLICY_US_OPENED");
  }

  render() {
    return (
      <Layout>
        <SEO title="Cookie Policy" disableGoogleCrawler={true}/>
        <div className="main-content lh2">
          <CookiePolicyComponent isGlobal={true} />
        </div>
      </Layout>
    );
  }
}
export default CookiePolicyUS;
